import React, {
  useState,
  useEffect
} from 'react';

import Web3 from 'web3'
import {
  ethers
} from 'ethers'
import contrato_token from '../abis/airnft.json'
import contrato_staking from '../abis/StakingToken.json'
import mainLogo from'../images/logo.png';

const VTXAdd = "0x38757bE34435d67E4aD2dC3abA2aaF4061EfD91B";
const StakingAdd = "0xA59906600aE0889b45e50Ebaf5f6A3f6538582FC";
const str_dec = "Gwei";

const App = () => {

  const [VTAToken, setVTATokens] = useState(null)
  const [stakeTokens, setStakeTokens] = useState(null)
  const [eth_account, setAccount] = useState(0)
  const [e_message, set_message] = useState(0)
  const [samount, setsamount] = useState(0)
  const [eamount, seteamount] = useState(0)

  const [mtx, setMtx] = useState(0)
  const [tokenInput, setTokenInput] = useState('')

  useEffect(() => {
    setInterval(ViewTokenBalance,3000);
  }, [VTAToken,stakeTokens]);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      window.alert('Non ethereum browser detected. You should consider trying Metamask!')
    }
    const accounts = await window.web3.eth.getAccounts()
    const account = accounts[0]
    setAccount(account)    
  }

  // Carga de los datos de la Blockchain

  const loadBlockchainData = async () => {

    const abi = contrato_token
    const abi2 = contrato_staking

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract_vta = new ethers.Contract(VTXAdd, abi, signer)
    const contract_reward = new ethers.Contract(StakingAdd, abi2, signer)

    setVTATokens(contract_vta)
    setStakeTokens(contract_reward)
  }


  const AddToken = async () => {
    const num = window.web3.utils.toWei(tokenInput, str_dec)

    const tx = await VTAToken.approve(StakingAdd, num)          
    await tx.wait();
    await stakeTokens.stake(num);
    ViewTokenBalance();
  }

  const Compound = async () => {
    stakeTokens.compound()
  }

  const Claim = async () => {
    stakeTokens.claim()
  }

  const Withdraw = async() => {
    stakeTokens.withdraw()
  }

  const ViewTokenBalance = async () => {            
    if(VTAToken)
    {
      const response = await VTAToken.balanceOf(eth_account)
      const hex = response._hex;
      const num = window.web3.utils.fromWei(hex, str_dec)      
      if (response == null) {
        setMtx(0)
      } else {
        setMtx(num)
      }
    }
    if(stakeTokens)
    {
      let response = await stakeTokens._balances(eth_account)
      let hex = response._hex;
      let num = window.web3.utils.fromWei(hex, str_dec)
      setsamount(num);      
      response = await stakeTokens._rewards(eth_account)
      hex = response._hex;
      num = window.web3.utils.fromWei(hex, str_dec)
      seteamount(num);      
    }
  }

  const LoadingAll = async () => {
    await loadWeb3()
    await loadBlockchainData()
    await ViewTokenBalance()
  }

  useEffect(() => {
    LoadingAll()
  }, [])

  return ( 
    <div>
      <header className="header">
      <nav>
        <div className="icon">
          <img  src={mainLogo} alt="fireSpot"/>
        </div>
              <div className="balance">
                  <h3>BALANCE</h3>
                  <div className="amount button-style">{mtx} VLT</div>
              </div>
              <div className="wallet_address">wallet address <br/>{eth_account}</div>
          </nav>
      </header>
      <main className="main">
          <h2>STAKE YOUR VLT TOKENS</h2>
          <div className="middle-chart">
              <div className="container-options">
                  <div className="amount-number white">
                    <input type="text" placeholder="0" onChange={(event)=>{setTokenInput(event.target.value)}}/>
                  </div>                  
                  <button className="btn" onClick = {()=>{AddToken()}}><p>STAKE</p></button>
              </div>
              <div className="container-options">
                  <div className="amount-number button-style">
                      <h3>{samount}</h3>
                  </div>
                    <p className="p_stake">Your Staked VLT</p>                   
                  <button className="btn red" onClick = {()=>{Withdraw()}}><p>UNSTAKE</p></button>
              </div>
          </div>
          <div className="bottom">
              <h3>YOUR REWARDS EARNED</h3>
              <div className="bottom_p"><p>{eamount} PRP</p></div>
              <div className="buttons-bottom">
                  <button className="btn" onClick = {()=>{Compound()}}><p>COMPOUND</p></button>
                  <button className="btn orange" onClick = {()=>{Claim()}}><p>CLAIM</p></button>
              </div>
          </div>
          
      </main>
    </div>
  );

}

export default App;